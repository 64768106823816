<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("notifications.notifications.add-notification") }}
        </div>
        <template v-slot:right>
          <button
            @click="initSubmit"
            v-if="
              $route.name == 'r_add-company-notification' &&
                can('MANAGE_NOTIFICATIONS', 'update')
            "
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      ref="addCompanyNotificationForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("notifications.notifications.title") }}
              </div>
              <p class="text-label">{{ displayCompanyNotificationName }}</p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_add-company-notification-translate-name',
                  params: $route.params,
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            v-model="hasNameTranslations"
          />
        </li>

        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.has_translations }"
        >
          <div class="error-message" v-if="errors.has_translations">
            {{ errors.has_translations }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <div class="clebex-section-input-label">
                {{ displayLabelName("notifications.notifications.body") }}
              </div>
              <p class="text-label">
                {{ displayCompanyNotificationDescription }}
              </p>
            </div>
            <span class="follow-up-icons">
              <router-link
                :to="{
                  name: 'r_add-company-notification-translate-description',
                  params: $route.params,
                }"
                class="follow-up-icon-item"
              >
                <icon icon="#cx-men1-translations" />
              </router-link>
            </span>
          </div>
          <Field
            name="has_translations"
            as="input"
            type="hidden"
            rules="required"
            v-model="hasTranslations"
          />
        </li>
      </ul>
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.level }"
          style="cursor: pointer;"
        >
          <router-link
            :to="{
              name: 'r_add-company-notification-send-to',
              params: $route.params,
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
              >
                {{ displayLabelName("notifications.notifications.send-to") }}
              </label>
              <p
                v-if="sendTo"
                class="text-label"
                style="cursor: pointer;"
                v-html="sendTo"
              ></p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                  style="cursor: pointer;"
                />
              </span>
            </span>
          </router-link>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.level }"
          style="cursor: pointer;"
        >
          <router-link
            :to="{
              name: 'r_add-company-notification-send-at',
            }"
            class="clebex-item-content-wrapper"
          >
            <div class="clebex-section-input" style="cursor: pointer;">
              <label
                class="clebex-section-input-label"
                style="cursor: pointer;"
              >
                {{ displayLabelName("notifications.notifications.send-at") }}
              </label>
              <p
                class="text-label"
                style="cursor: pointer;"
                v-html="sendAt"
              ></p>
            </div>
            <span class="follow-up-icons" style="cursor: pointer;">
              <span class="follow-up-icon-item" style="cursor: pointer;">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                  style="cursor: pointer;"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth, { getLang } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";

export default {
  name: "AddCompanyNotification",
  data() {
    return {
      formKey: 0,
    };
  },
  created() {
    this.setTranslations();
    this.setSendTo(null);
    this.setSendAt(null);
    this.setDatetimeFrom(null);
    this.setDatetimeTo(null);
    this.setDay(null);
    this.setAllUsers(null);
    this.setUserContainers([]);
    this.setUsers([]);
  },
  computed: {
    ...mapState("companyNotifications", [
      "companyNotificationTranslations",
      "datetimeFrom",
      "datetimeTo",
      "day",
      "users",
      "allUsers",
      "userContainers",
      "sendTo",
      "sendAt",
    ]),
    ...mapState("language", ["languages"]),
    hasNameTranslations() {
      const { languages, companyNotificationTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === companyNotificationTranslations.length;
      }
      return false;
    },
    hasDescriptionTranslations() {
      const { languages, companyNotificationTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return languages.data.length === companyNotificationTranslations.length;
      }
      return false;
    },
    displayCompanyNotificationName() {
      const { companyNotificationTranslations } = this;
      const locale = getLang();
      if (
        companyNotificationTranslations &&
        companyNotificationTranslations.length
      ) {
        const filtered = companyNotificationTranslations.filter(
          (item) => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    displayCompanyNotificationDescription() {
      const { companyNotificationTranslations } = this;
      const locale = getLang();
      if (
        companyNotificationTranslations &&
        companyNotificationTranslations.length
      ) {
        const filtered = companyNotificationTranslations.filter(
          (item) => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.description;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  watch: {
    languages() {
      this.setTranslations();
    },
  },
  methods: {
    ...mapActions("companyNotifications", [
      "getCompanyNotifications",
      "setCompanyNotificationTranslations",
      "setSendTo",
      "setSendAt",
      "setDay",
      "setDatetimeFrom",
      "setDatetimeTo",
      "setUsers",
      "setAllUsers",
      "setUserContainers",
    ]),
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    setTranslations() {
      if (this.languages && this.languages.data) {
        let translations = [];
        this.languages.data.forEach((language) => {
          let object = {
            locale: language.locale,
            attributes: {
              name: "",
              description: "",
            },
          };
          translations.push(object);
        });
        this.setCompanyNotificationTranslations(translations);
      }
    },
    sortLanguages(a, b) {
      const currentLang = getLang();
      if (a.locale === currentLang && b.locale !== currentLang) {
        return -1;
      }

      if (a.locale !== currentLang && b.locale === currentLang) {
        return 1;
      }

      return 0;
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      if (this.companyNotificationTranslations.length) {
        values.translations = this.companyNotificationTranslations;
      }

      values.datetime_from = this.datetimeFrom;
      values.datetime_to = this.datetimeTo;
      values.all_users = this.allUsers;
      values.user_containers = this.userContainers;
      values.users = this.users;
      values.day = this.day;

      httpServiceAuth
        .post(apiEndpoints.company.companyNotifications, values)
        .then((response) => {
          this.getCompanyNotifications();
          this.$store.commit(
            "companyNotifications/setCompanyNotificationTranslations",
            [],
            {
              root: true,
            }
          );
          setTimeout(() => {
            if (this.formKey === 10) {
              this.formKey = 0;
            } else {
              this.formKey++;
            }
          }, 0);
          this.$router.push({
            name: "r_edit-company-notification",
            params: { company_notification_id: response.data.data.id },
          });
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
  },
  beforeUnmount() {
    this.$store.commit(
      "companyNotifications/setCompanyNotificationTranslations",
      [],
      {
        root: true,
      }
    );
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
